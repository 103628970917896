import React from 'react';
import { Home } from '@mui/icons-material';

export const steps = [
  {
    title: 'Step 1',
    timeFrame: 'Jan 2023 - Feb 2023',
    stepNumber: 1,
    image: <Home />,
    shortDescription: 'This is the first step.',
    longDescription: 'This is a more detailed description of the first step.',
  },
  {
    title: 'Step 2',
    timeFrame: 'Feb 2023 - Mar 2023',
    stepNumber: 2,
    image: <Home />,
    shortDescription: 'This is the second step.',
    longDescription: 'This is a more detailed description of the second step.',
  },
  {
    title: 'Step 3',
    timeFrame: 'Mar 2023 - Apr 2023',
    stepNumber: 3,
    image: <Home />,
    shortDescription: 'This is the third step.',
    longDescription: 'This is a more detailed description of the third step.',
  },
  {
    title: 'Step 4',
    timeFrame: 'Apr 2023 - May 2023',
    stepNumber: 4,
    image: <Home />,
    shortDescription: 'This is the fourth step.',
    longDescription: 'This is a more detailed description of the fourth step.',
  },
  {
    title: 'Step 5',
    timeFrame: 'May 2023 - Jun 2023',
    stepNumber: 5,
    image: <Home />,
    shortDescription: 'This is the fifth step.',
    longDescription: 'This is a more detailed description of the fifth step.',
  }
];
