import React, { useContext, useState } from 'react';
import { Box } from '@mui/material';
import { themeToggle } from '../index';
// import { useTheme } from '@emotion/react';
import {useTheme} from '@mui/material/styles'
import { CustomTheme } from '../types/types';
import DesktopMenu from '../components/Header/DesktopMenu';
import MobileMenu from '../components/Header/MobileMenu';

const Header: React.FC = () => {
  const [navMenu, setNavMenu] = useState<HTMLElement | null>(null);
  const { toggleColorMode } = useContext(themeToggle);

  const pages = [
    { path: "contact", name: "Contact Us" },
    { path: "timeline", name: "Timeline" }
  ]; 

  const theme = useTheme() as CustomTheme;

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => setNavMenu(event.currentTarget);
  const handleCloseNavMenu = () => setNavMenu(null);

  return (
    <Box
      id="header"
      alignItems="center"
      sx={{
        backgroundColor: theme.palette.background?.default,
        color: theme.palette.text?.primary,
        padding: '0 16px',
      }}
    >
      <MobileMenu
        navMenu={navMenu}
        handleOpenNavMenu={handleOpenNavMenu}
        handleCloseNavMenu={handleCloseNavMenu}
        pages={pages}
        toggleColorMode={toggleColorMode}
        theme={theme}
      />
        <DesktopMenu
          pages={pages}
          toggleColorMode={toggleColorMode}
          theme={theme}
        />
    </Box>
  );
};

export default Header;
