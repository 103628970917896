import { createTheme } from '@mui/material/styles';
import { CustomTheme } from './types'; 

const darkTheme: CustomTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#000C22',
    },
    text: {
      primary: '#EBF5EE',
    },
    primary: {
      main: '#2F4858',
    },
    secondary: {
      main: '#54C8E0',
    },
    divider: '#FFEAD0',
  },
  typography: {
    fontFamily: 'Rubik, Arial, sans-serif',
  },
});

const lightTheme: CustomTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#EBF5EE',
    },
    text: {
      primary: '#000C22',
    },
    primary: {
      main: '#2F4858',
    },
    secondary: {
      main: '#54C8E0',
    },
    divider: '#FFEAD0',
  },
  typography: {
    fontFamily: 'Rubik, Arial, sans-serif',
  },
});

export { darkTheme, lightTheme };
