import React, { useState } from 'react';
import ChatWindow from './ChatBot/ChatWindow';
import PromptInput from './ChatBot/PromptInput';
import LoadingIndicator from './ChatBot/LoadingIndicator';

interface AiModelProps {
  model: 'assistant' | 'neuratrain';
  messages: { content: string; role: 'user' | 'ai' }[];
  setMessages: React.Dispatch<React.SetStateAction<{ content: string; role: 'user' | 'ai' }[]>>;
}

const AiModel: React.FC<AiModelProps> = ({ model, messages, setMessages }) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      <ChatWindow messages={messages} />
      <PromptInput
        messages={messages}
        setMessages={setMessages}
        setLoading={setLoading}
        model={model}
      />
      <LoadingIndicator loading={loading} />
    </>
  );
}

export default AiModel;
