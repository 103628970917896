import React, { useState } from 'react';
import { Box, Paper, Typography, Badge, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Step {
  title: string;
  timeFrame: string;
  stepNumber: number;
  image?: string | React.ReactElement;
  shortDescription: string;
  longDescription: string;
}

interface TimelineStepProps {
  step: Step;
}

const TimelineStep: React.FC<TimelineStepProps> = ({ step }) => {
  const { title, timeFrame, stepNumber, image, shortDescription, longDescription } = step;
  const theme = useTheme();
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  const containerStyles = {
    p: 2,
    position: 'relative',
    width: hovered ? 150 : 130,  // Reduced size for fitting text
    height: hovered ? 150 : 130,
    borderRadius: '50%',  // Circular shape
    bgcolor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 0.3s ease',
    transform: hovered ? 'scale(1.1)' : 'scale(1)',
    boxShadow: hovered ? `0px 4px 10px ${theme.palette.grey[500]}` : 'none',
    cursor: 'pointer',
    textAlign: 'center',
  };

  const textStyle = {
    fontSize: hovered ? '0.9rem' : '0.8rem',  // Smaller font size for better fit
    textAlign: 'center',
  };

  return (
    <Paper
      elevation={3}
      sx={containerStyles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Badge badgeContent={stepNumber} color="primary" sx={{ position: 'absolute', top: 8, left: 8 }} />
      {image && (
        <Box sx={{ mb: 1 }}>
          {typeof image === 'string' ? (
            <Box component="img" src={image} alt={title} sx={{ width: 40, height: 40 }} />
          ) : (
            image
          )}
        </Box>
      )}
      <Typography variant="h6" sx={{ fontSize: '1rem', textAlign: 'center', mb: 1 }}>{title}</Typography>
      <Typography variant="body2" color="textSecondary" sx={textStyle}>{timeFrame}</Typography>
      <Box sx={{ mt: 1 }}>
        {hovered ? (
          <>
            <Typography variant="body2" sx={textStyle}>{longDescription}</Typography>
            <Button variant="contained" color="primary" sx={{ mt: 1 }}>
              Learn More
            </Button>
          </>
        ) : (
          <Typography variant="body1" sx={textStyle}>{shortDescription}</Typography>
        )}
      </Box>
    </Paper>
  );
};

export default TimelineStep;
