import React, { useState, useMemo, createContext } from "react";
import ReactDOM from "react-dom/client";
import { useMediaQuery, ThemeProvider } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Timeline from "./pages/Timeline";
import Contact from "./pages/Contact";
import Success from "./pages/Success";
import { CustomTheme } from "./types/types";
import "./css/index.css";
import { darkTheme, lightTheme } from "./types/customTheme";

const Routes: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path:"/Timeline",
      element: <Timeline/>
    },
    {
      path:"/Contact",
      element: <Contact/>
    },
    {
      path:"/Success",
      element: <Success/>
    }
  ]);

  return <RouterProvider router={router} />;
};

interface ThemeContextProps {
  toggleColorMode: () => void;
}

export const themeToggle = createContext<ThemeContextProps>({
  toggleColorMode: () => {},
});

// Theme Selection Hook
const useThemeSelection = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState<"light" | "dark">(
    prefersDarkMode ? "dark" : "light"
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = useMemo(
    () => (mode === 'light' ? lightTheme:darkTheme) as CustomTheme,
    [mode]
  );

  return { colorMode, theme };
};

const App: React.FC = () => {
  const { theme, colorMode } = useThemeSelection();

  return (
    <themeToggle.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
          <Routes />
      </ThemeProvider>
    </themeToggle.Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

