import React, { useState } from 'react';
import { Container, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import AiModel from '../components/AiModel';
import Layout from '../layout/Layout';

const Home: React.FC = () => {
  const [model, setModel] = useState<"assistant" | "neuratrain">('neuratrain');
  const [assistantMessages, setAssistantMessages] = useState<{ content: string; role: 'user' | 'ai' }[]>([]);
  const [neuratrainMessages, setNeuratrainMessages] = useState<{ content: string; role: 'user' | 'ai' }[]>([]);

  const handleModelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModel((event.target as HTMLInputElement).value as "assistant" | "neuratrain");
  };

  const currentMessages = model === 'assistant' ? assistantMessages : neuratrainMessages;
  const setCurrentMessages = model === 'assistant' ? setAssistantMessages : setNeuratrainMessages;

  return (
    <Layout>
      <Container>
        <Grid container spacing={2}>
          {/* Options/Filters Section */}
          <Grid item xs={12} md={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Choose Model</FormLabel>
              <RadioGroup value={model} onChange={handleModelChange}>
                <FormControlLabel value="neuratrain" control={<Radio />} label="NeuraTrain" />
                <FormControlLabel value="assistant" control={<Radio />} label="Assistant" />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* AI Model Section */}
          <Grid item xs={12} md={9}>
            <AiModel
              model={model}
              messages={currentMessages}
              setMessages={setCurrentMessages}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export default Home;
