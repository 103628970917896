import React from "react";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomTheme } from "../../types/types";

interface ThemeProps {
  theme: CustomTheme;
}

const Logo: React.FC<ThemeProps> = ({ theme }) => {
  const navigate = useNavigate();

  const logo =
    theme.palette.mode === "dark"
      ? "./favicon.ico"
      : "./favicon.ico";

  return (
    <IconButton onClick={() => navigate("/")}>
      <img src={logo} alt="Neuratuneai Logo" style={{ width: 40, height: 40 }} />
    </IconButton>
  );
};

export default Logo;
