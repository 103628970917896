import React, { useState } from 'react';
import { TextField, Box, IconButton } from '@mui/material';
import SendSharpIcon from '@mui/icons-material/SendSharp';

interface PromptInputProps {
  messages: { content: string; role: 'user' | 'ai' }[];
  setMessages: React.Dispatch<React.SetStateAction<{ content: string; role: 'user' | 'ai' }[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  model: 'neuratrain' | 'assistant';
}

const PromptInput: React.FC<PromptInputProps> = ({ messages, setMessages, setLoading, model }) => {
  const [inputValue, setInputValue] = useState<string>('');
  
  const handleSend = async () => {
    if (inputValue.trim() === '') return;

    const newMessage = { content: inputValue, role: 'user' as const };
    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);
    setInputValue('');
    setLoading(true);

    try {
      const response = await fetch(`https://neuratrainai-server.hsgill292.workers.dev/api/${model}/chat`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ messages: updatedMessages }),
      });

      if (!response.ok) {
        console.error('Failed to fetch AI response', await response.text());
        return;
      }

      const reader = response.body?.getReader();
      if (!reader) throw new Error('ReadableStream is not available');

      const decoder = new TextDecoder();
      let accumulatedData = '';
      let result = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        accumulatedData += decoder.decode(value, { stream: true });
        const boundary = accumulatedData.lastIndexOf('\n');
        const lines = accumulatedData.substring(0, boundary).split('\n').filter(line => line.trim() !== '');

        for (const line of lines) {
          const trimmedLine = line.replace("data: ", "").trim();
          if (trimmedLine === '[DONE]') break;

          try {
            const json = JSON.parse(trimmedLine);
            if (json.response) {
              if (json.response === '</s>') break;
              result += json.response;
              setMessages([...updatedMessages, { content: result, role: 'ai' as const }]);
            }
          } catch (err) {
            console.error('Error parsing JSON:', err);
          }
        }

        accumulatedData = accumulatedData.substring(boundary + 1);
      }
    } catch (error) {
      console.error('Error fetching AI response:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSend();
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            fullWidth
            label="Prompt"
            id="promptEntry"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            sx={{ marginRight: 2, color: 'divider' }}
          />
        </Box>
        <IconButton
          type="submit"
          color="secondary"
          aria-label="send prompt"
          sx={{
            backgroundColor: 'primary.main',
            borderRadius: 1,
            height: '56px',
            width: '56px',
            padding: 1,
            '&:hover': { backgroundColor: 'lightgray' },
          }}
        >
          <SendSharpIcon sx={{ color: 'secondary.main' }} />
        </IconButton>
      </form>
    </Box>
  );
};

export default PromptInput;
