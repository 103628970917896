import React, { ReactNode } from "react";
import Header from "./Header";
import { useTheme } from '@mui/material/styles';
import { CustomTheme } from '../types/types';
import { Container, Paper } from "@mui/material";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const theme = useTheme() as CustomTheme;

  return (
    <Container
      id="outer-container"
      disableGutters
      maxWidth={false}
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }}
    >
      <Header />
      <Paper
        square
        elevation={5}
        className="page-content"
        sx={{
          backgroundColor: theme.palette.background?.default,
          color: theme.palette.text?.primary,
          padding: 2,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div style={{ flex: 1, overflowY: 'auto' }}>
          {children}
        </div>
      </Paper>
    </Container>
  );
};

export default Layout;
