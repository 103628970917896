import React, { useRef, useEffect } from 'react';
import { Paper, Box, Typography } from '@mui/material';

interface ChatWindowProps {
  messages: { content: string; role: 'user' | 'ai' }[];
}

const ChatWindow: React.FC<ChatWindowProps> = ({ messages }) => {
  const paperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (paperRef.current) {
      paperRef.current.scrollTop = paperRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Paper
      square={false}
      ref={paperRef}
      sx={{
        marginTop: 2,
        padding: 2,
        borderRadius: 2,
        minHeight: 500,
        maxHeight: 500,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'primary.main',
        color: 'divider',
        '&::-webkit-scrollbar': { width: '8px' },
        '&::-webkit-scrollbar-track': { backgroundColor: 'background.default' },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'primary.main',
          borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': { backgroundColor: 'text.primary' },
      }}
    >
      {messages.map((msg, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: msg.role === 'ai' ? 'flex-start' : 'flex-end',
            marginBottom: 1,
            padding: 1,
            backgroundColor: msg.role === 'ai' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            borderRadius: 1,
            maxWidth: 'fit-content',
            alignSelf: msg.role === 'ai' ? 'flex-start' : 'flex-end',
            paddingLeft: msg.role === 'ai' ? 2 : 1,
            paddingRight: msg.role === 'user' ? 2 : 1,
          }}
        >
          <Typography variant="body1">{msg.content}</Typography>
        </Box>
      ))}
    </Paper>
  );
};

export default ChatWindow;
