import React from 'react';
import { Grid } from '@mui/material';
import Logo from './Logo';
import ThemeToggle from './ThemeToggle';
import HeaderButton from './Buttons/HeaderButton';

interface PagesProps {
  path: string,
  name: string
}

interface DesktopMenuProps {
  pages: PagesProps[];
  toggleColorMode: () => void;
  theme: any;
}

const DesktopMenu: React.FC<DesktopMenuProps> = ({ pages, toggleColorMode, theme }) => {

  return (
    <Grid 
      container 
      sx={{ 
        display: { xs: 'none', sm: 'flex' },
        alignItems: 'center',
        paddingX: 2,
        width: '100%' 
      }}
    >
      
      <Grid 
        item 
        xs={4} 
        container
        alignItems="center"
      >
        {pages.map((page) => (
          <HeaderButton key={page.name} path={page.path} name={page.name} />
        ))}
      </Grid>

      <Grid 
        item 
        xs={4} 
        container 
        justifyContent="center"
        alignItems="center"
      >
        <Logo theme={theme} />
      </Grid>

      <Grid 
        item 
        xs={4} 
        container 
        justifyContent="flex-end"
        alignItems="center"
      >
        <ThemeToggle toggleColorMode={toggleColorMode} theme={theme} />
      </Grid>
    </Grid>
  );
};

export default DesktopMenu;
