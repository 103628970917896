import React from 'react';
import { Box, Container, CssBaseline, Avatar, Typography, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';
import Layout from '../layout/Layout';

const Success: React.FC = () => {
  return (
    <Layout>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            height: '90vh', 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'success.main' }}>
            <CheckCircleIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Message Sent Successfully!
          </Typography>
          <Typography component="p" sx={{ mt: 2, textAlign: 'center' }}>
            Thank you for reaching out. We have received your message and will get back to you shortly.
          </Typography>
          <Button
            component={Link}
            to="/"
            variant="contained"
            color="primary"
            sx={{ mt: 4 }}
          >
            Back to Home
          </Button>
        </Box>
      </Container>
    </Layout>
  );
};

export default Success;
