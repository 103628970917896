import React from 'react';
import { Box, Grid } from '@mui/material';
import TimelineStep from './TimelineStep';
import { steps } from './steps';

const TimelineComponent: React.FC = () => {
    const startingStep = steps[0];
    const endingStep = steps[steps.length - 1];
    const remainingSteps = steps.slice(1, -1);

    const sortedSteps = [...remainingSteps].sort((a, b) => a.stepNumber - b.stepNumber);

    const centerLineStyles = {
        position: 'absolute',
        top: '50%',
        left: '10%',
        right: '10%',
        height: '2px',
        bgcolor: 'primary.main',
        zIndex: 1,
    };

    const gridItemStyles = {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
    };

    return (
        <Box sx={{ my: 4, maxWidth: '100%', position: 'relative', overflowX: 'auto' }}>
            {/* Horizontal Line for Timeline */}
            <Box sx={centerLineStyles} />

            <Grid container spacing={2} sx={{ height: '400px', alignItems: 'stretch', position: 'relative' }}>
                {/* Left Column - Starting Step */}
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 2 }}>
                    <TimelineStep step={startingStep} />
                </Grid>

                {/* Center Column - Remaining Steps */}
                <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    {sortedSteps.map((step, index) => (
                        <Box
                            key={index}
                            sx={{
                                ...gridItemStyles,
                                height: '100%',
                                justifyContent: step.stepNumber % 2 === 1 ? 'flex-end' : 'flex-start',  // Top or bottom alignment
                            }}
                        >

                            {/* Spoke that connects the line to the step */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: step.stepNumber % 2 === 1 ? '50%' : '',
                                    left: '50%',
                                    bottom: step.stepNumber % 2 === 0 ? '50%' : '',
                                    width: '2px',
                                    height: '30px',
                                    bgcolor: 'primary.main',
                                    zIndex: 1,
                                }}
                            />
                            <TimelineStep step={step} />
                        </Box>
                    ))}
                </Grid>

                {/* Right Column - Ending Step */}
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 2 }}>
                    <TimelineStep step={endingStep} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default TimelineComponent;
