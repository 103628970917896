import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../layout/Layout';
import { Container, CssBaseline, Box, Avatar, Typography, Grid, TextField, Button, Snackbar, Alert, CircularProgress } from '@mui/material';
import ContactsIcon from '@mui/icons-material/Contacts';
import Autocomplete from '@mui/material/Autocomplete';

const industries = [
  { value: 'Not Listed', label: 'Not Listed' },
  { value: 'technology', label: 'Technology' },
  { value: 'automotive', label: 'Automotive' },
  { value: 'engineering', label: 'Engineering' },
  { value: 'finance', label: 'Finance' },
  // ADD MORE HERE
];

const Contact: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [selectedIndustry, setSelectedIndustry] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);

    const formData = {
      name: `${data.get('firstName')} ${data.get('lastName')}`,
      businessName: data.get('businessName'),
      industry: selectedIndustry || data.get('industry'),
      email: data.get('email'),
      message: data.get('message'),
    };

    try {
      const response = await fetch('https://neuratrainai-server.hsgill292.workers.dev/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        setSnackbarMessage(result.message);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        navigate('/Success');
      } else {
        setSnackbarMessage('Failed to submit the contact form. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error submitting contact form:', error);
      setSnackbarMessage('An error occurred while submitting the form.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <ContactsIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Contact Us!
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  autoComplete="given-name"
                  name="firstName"
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="businessName"
                  label="Name of Business"
                  name="businessName"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  options={industries.map((option) => option.label)}
                  onChange={(event, newValue) => setSelectedIndustry(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Industry"
                      helperText="Please select or type the industry of your Business"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows="4"
                  name="message"
                  label="Enter your message here"
                  id="contactMSG"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Send'}
            </Button>
          </Box>
        </Box>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default Contact;
