import React from 'react';
import { Box, LinearProgress } from '@mui/material';

interface LoadingIndicatorProps {
  loading: boolean;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ loading }) => {
  if (!loading) return null;

  return (
    <Box sx={{ marginTop: 0 }}>
      <LinearProgress
        sx={{
          width: '100%',
          backgroundColor: 'secondary.main',
          borderRadius: '4px',
          '&.MuiLinearProgress-bar': { borderRadius: '4px' },
        }}
      />
    </Box>
  );
};

export default LoadingIndicator;
