import React from 'react';
import TimelineComponent from '../components/Timeline/TimelineComponent';
import Layout from '../layout/Layout';

const Timeline: React.FC = () => {
  return (
    <div>
      <Layout>
        <TimelineComponent />
      </Layout>
    </div>
  );
};

export default Timeline;
